<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-0">
        {{ questionText }}
      </p>
      <v-radio-group v-model="inputs.input1" :disabled="!allowEditing" class="mt-1 pl-6">
        <div v-for="option in options1" :key="option.value">
          <v-radio class="d-inline-block" :value="option.value">
            <template #label>
              <stemble-latex :content="option.text" />
            </template>
          </v-radio>
        </div>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'UPEISafety9',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
    };
  },
  computed: {
    questionNumber() {
      return this.taskState.getValueBySymbol('questionNumber').content;
    },
    questionText() {
      if (this.questionNumber.value === 1) {
        return 'When are non-medical masks required in the laboratory?';
      } else if (this.questionNumber.value === 2) {
        return 'Are cloth masks allowed in the labs?';
      } else {
        return 'If you feel unwell prior to lab, what should you do?';
      }
    },
    options1() {
      if (this.questionNumber.value === 1) {
        return [
          {text: 'Always', value: '1'},
          {text: 'On occasion', value: '2'},
          {text: 'Never', value: '3'},
        ];
      } else if (this.questionNumber.value === 2) {
        return [
          {text: 'Yes', value: '1'},
          {text: 'No', value: '2'},
          {text: 'Only if it has a really cool design', value: '3'},
        ];
      } else {
        return [
          {
            text: 'Come to lab',
            value: '1',
          },
          {
            text: "Don't come to lab but notify your lab instructor ASAP",
            value: '2',
          },
          {
            text: "Don't come to lab and tell no one",
            value: '3',
          },
          {
            text: 'Come to lab and see what your instructor thinks you should do',
            value: '4',
          },
        ];
      }
    },
  },
};
</script>
